'use client';

import { useTranslation } from '@/i18n/client';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Locale } from '../../../i18n-config';
import Image from 'next/image';

type Props = {};

const SiteSocialNetworkComponent = (props: Props) => {
  const params = useParams();
  const { t } = useTranslation(params.lang as Locale);

  return (
    <section
      id="footer-socialNetwork"
      className="gap-3 py-5 pb-4 d-flex flex-column justify-content-center align-content-center align-items-center bg-firstColor"
    >
      <Container>
        <Row className="g-lg-4 g-3">
          <Col lg={7} className="px-3">
            <Row className="mb-2">
              <Col>
                <Link href={`/${params.lang}`} className="text-decoration-none color-twelveColor">
                  <span className="fs-2 fw-bolder font-poppins">LouiseBot</span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="color-sevenColor fs-5 font-sofiaSans">{t('home/SiteSocialNetwork/text-01')}</p>
              </Col>
            </Row>
          </Col>

          <Col lg={2} className="px-3">
            <span className="fs-3 fw-bolder font-poppins color-sevenColor tw-mb-5 tw-block">
              {t('home/SiteSocialNetwork/link-01')}
            </span>
            <Link className="nav-link color-sevenColor font-sofiaSans mb-3" href={`/${params.lang}/plans`}>
              {t('home/SiteSocialNetwork/link-02')}
            </Link>
            <Link
              className="nav-link color-sevenColor font-sofiaSans"
              target="_blank"
              href={`/${params.lang}/terms-of-service`}
            >
              {t('home/SiteSocialNetwork/link-03')}
            </Link>
          </Col>

          <Col lg={2} className="px-3">
            <Link
              className="nav-link color-sevenColor font-sofiaSans mb-3 md:tw-mt-12"
              href={`/${params.lang}/contact-us`}
            >
              {t('home/SiteSocialNetwork/link-04')}
            </Link>

            <Link
              target="_blank"
              className="nav-link color-sevenColor font-sofiaSans"
              href={`/${params.lang}/frequently-asked-questions`}
            >
              {t('home/SiteSocialNetwork/link-05')}
            </Link>
          </Col>

          <Col lg={1} xs={4}>
            <Image
              src={'https://cdn.1cdn.app/application/LOUISEBOT/24041606581499_logo-gdpr-compliant.svg'}
              alt="GDRP"
              width={100}
              height={100}
              className="tw-max-w-[100px]"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SiteSocialNetworkComponent;
